import React, {Component} from 'react';

class Card extends Component{ 

    constructor(props) {
        super(props);
        this.state = {id: props.data.link, data: props.data}; 
        this.closeCard = this.closeCard.bind(this);
    }

    choseMedium(){
        var medium;
        if(this.props.data.media.type ==="video"){
            medium = (
                <video 
                    id={"videoplayer-"+this.props.data.link}
                    controls
                >
                    <source src={this.props.data.media.uri} type="video/mp4" />
                </video>
            )
        } else if (this.props.data.media.type==="iframe"){
            medium = (
                <div>
                    <iframe 
                        id="iframe-player-id" 
                        title="iframeplayer"  
                        src="https://lernspielchen.de/wp-admin/admin-ajax.php?action=h5p_embed&id=4"  
                        width="958" 
                        height="564" 
                        frameBorder="0" 
                        allowFullScreen="allowfullscreen">
                    </iframe>
                    <script 
                        src="https://lernspielchen.de/wp-content/plugins/h5p/h5p-php-library/js/h5p-resizer.js" 
                        charSet="UTF-8">
                    </script>    
                </div>                
            )
        }
        return medium;
    }

    closeCard(e){
        if (this.props.data.media.type === "video"){
            document.getElementById("videoplayer-"+e.target.value).pause()
        }
        document.getElementById("card-wrapper-"+e.target.value).style.display = "none"
    }

    createExternalLinks(){
        var field = <></>;
        if(typeof this.props.data.externLink !== undefined){
            field = (
                <>
                    <h2
                        style={{
                            margin: '1em 0 .3em 0',
                        }}
                    >
                        Weiterführende Links
                    </h2>
                    <div className="separator"
                        style={{
                            width: 40,
                            height: 10,
                            background: this.props.data.color,
                            margin: '0 0 1em 0'
                        }}
                    ></div>
                    <p>Weitere Informationen gibt es <a className="info-link" href={this.props.data.externLink}>hier</a></p>
                </>
            )
        }
        return field
    }

    render(){
        var textfield;
        if (Array.isArray(this.props.data.fachschaftsBeschreibung)){
            textfield.push((this.props.data.fachschaftsBeschreibung.map((e) => 
                <p
                    key={e}
                >
                    {e}
                </p>
            )))
        } else {
            textfield = (this.props.data.description)   
        }
        return(
            <div
                className="card-wrapper"
                id={"card-wrapper-"+this.props.data.link}
                style={{
                    position: 'fixed',
                    background: 'whitesmoke',
                    gridTemplateRows: '2',
                    gridTemplateColumns: '2',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    justifySelf: 'center',
                    margin: '1%',
                    padding: '1%',
                    borderRadius: 20,
                    width : '1100px',
                    maxHeight: '80vh',
                    zIndex: 101,
                    boxShadow: '0px 10px 20px #848484',
                    overflowY: 'auto'  
                }}
            >
                <button
                    style={{
                        float: 'right',
                        background: "#FF3232",
                        color: 'white',
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        position: 'sticky',
                        top: 0,
                        right: '0%',
                        border: "none",
                        borderRadius: '50%',
                        fontSize: '2em',
                        zIndex: 1
                    }}
                    value={this.props.data.link}
                    onClick = {this.closeCard}
                >
                    &#10006;
                </button>
                <div 
                    className="video-wrapper"
                    style={{
                        margin: '1em 0 0 0', 
                        display: "flex",
                        flexWrap: 'wrap',
                        
                    }}    
                >
                
                {this.choseMedium()}
                        
                </div>
                
                <div
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: '100%'
                    }}
                >
                    <h3
                        style={{
                            fontSize: '3em',
                            margin: '0 0 .2em 0'
                        }}    
                    >
                        {this.props.data.name}
                    </h3>
                    
                </div>
                <div className="separator"
                    style={{
                        width: 80,
                        height: 20,
                        background: this.props.data.color,
                        margin: '0 0 2em 0'
                    }}
                ></div>
                <p
                    style={{
                        width:'85%',
                        margin: '0 0 2em 0',
                    }}
                >{textfield}</p>
                {this.createExternalLinks()}
            </div>                    
        )    
    }
}   
export default Card;

/*

<div style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: '0'
                    }}   
                    >
                        <h2
                            style={{
                                margin: '0 0 .3em 0',
                            }}
                        >{this.props.data.media.title}</h2>
                        <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: this.props.data.color,
                                margin: '0 0 1em 0'
                            }}
                        ></div>
                        <p
                            style={{
                                margin: '0 0 2em 0',
                            }}
                        >{this.props.data.media.description}</p>
                    </div>

*/