import React, { Component } from 'react';

class Header extends Component{
    constructor(props){
        super(props);
        this.state = {currentSize: 150};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        document.getElementById("size-button-s").className="scale-button"
        document.getElementById("size-button-m").className="scale-button"
        document.getElementById("size-button-l").className="scale-button"
        document.getElementById("size-button-xl").className="scale-button"
        document.getElementById(e.currentTarget.id).className="scale-button selected"
        super.setState({currentSize: e.currentTarget.value})
        this.setState({currentSize:e.currentTarget.value})
    }

    render(){
        return (
            <header className="page-header">   
                <a href="https://www.fmsg-speyer.de/home"><img src="https://www.fmsg-speyer.de/files/fmsg/img/SCHWERD_logo.png" alt="Logo des fmsg"></img></a>               
                <div>
                    <h1 className="page-title">Der digitale Infotag</h1>
                    <h3 className="page-subtitle">des Friedrich Magnus Schwerd Gymnasium</h3>
                </div>
            </header>
        )
    }
}

export default Header;


/*

*/