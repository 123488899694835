//Modules
import React, { Component } from 'react';

//Maincomponent
import Home from './Home.js';

class App extends Component{
  constructor(props){
    super(props)
    this.state = {currentSize: 150}
  }
  render(){
    return (
      <div
      className="app-window"
        style={{
          width: '100%',
        }}
      >
        <Home
          currentSize = {this.state.currentSize} 
          className="content"
        /> 
      </div>

    )
  }
}

export default App;



/*
margin: 400,
          display: 'flex',
          flexDirection: 'row',
          flexFlow: 'wrap',
          justifyContent: 'center'
*/