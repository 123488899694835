import React, { Component } from 'react';
import Faq from './Faq';

class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {faqActive: false, impActive: false, elapsedTime: Date.now()};
        this.handleClickFaq = this.handleClickFaq.bind(this);
        this.handleClickImp = this.handleClickImp.bind(this);
    }

    handleClickFaq(){
        this.setState({faqActive:!this.state.faqActive, impActive:false})
    }

    handleClickImp(){
        this.setState({impActive:!this.state.impActive, faqActive: false})
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(),1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        if(Date.now()-this.state.elapsedTime > 1000){
            document.getElementById("footer").className = ("page-footer")
        }
    }

    render(){
        return (
            <footer className="page-footer modified" id="footer" >
                <ul>
                    <Faq data={{...this.state}}/>
                    <li><button href="#faq" onClick={this.handleClickFaq}>FAQs</button></li>
                    <li><a href="https://www.fmsg-speyer.de/impressum" >Impressum</a></li>
                    <li><a href="https://www.fmsg-speyer.de">Friedrich Magnus Schwerd Gymnasium</a> 2020 | Tobias Eckert</li>         
                </ul>
            </footer>
        )
    }

    
    
}

export default Footer;