import React, {Component} from 'react';

import luftbild from '../media/Drohnenansicht.JPG'

const interval = 3000;
var start =0;

class Center extends Component{ 
    constructor(props){
        super(props)
        start = Date.now();
        this.state = {timeElapsed: 0, title:"Willkommen am FMSG", actionTitle:"Klicke hier, um unsere Schule zu entdecken"}
        this.cardSize = 300
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );

    }
  
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            timeElapsed: (Date.now()-start)
        })
        if (this.state.timeElapsed >= interval){
            document.getElementById("layer-one-title-id").className="layer-one-title leave"
        }
        if (this.state.timeElapsed >= interval+1000){
            this.cardSize = this.props.size
            this.setState({title: this.state.actionTitle})
            document.getElementById("layer-one-title-id").className="layer-one-title transition"
            document.getElementById("center-title").style.opacity ="1"
        } 
    }

    render(){
        return(
            <div
                style={{
                    display:'flex',
                    width:  this.cardSize,
                    height:  this.cardSize,
                    borderRadius: '50%',
                    padding: '20px',
                }}
            >
                
                <img 
                    id="layer-one-title-id"
                    className="layer-one-title" 
                    src={luftbild} 
                    alt="Luftbild der Schule"
                    style={{
                        width:  this.cardSize,
                        height:  this.cardSize, 
                        objectFit: "cover", 
                        borderRadius: '50%',
                    }}
                >    
                </img>
                <h2 id="center-title"
                    style={{
                        display: "flex",
                        opacity: 0,
                        margin: 0,
                        padding: 0,
                        alignSelf:"center",
                        justifySelf:"center",
                        position: 'absolute',
                        textAlign: 'center',
                        verticalAlign: 'center',
                        transition: '1000ms',
                        fontSize: this.cardSize/7,
                        width: this.cardSize,
                        height: this.cardSize,
                        borderRadius: '50%', 
                        color: "black"
                    }}
                >
                    <br></br>Klicke hier, um unsere Schule zu entdecken
                </h2>
            </div>
            
        )
    }
}   
export default Center; 

/*

<h1 className="layer-one-title"
                    id="layer-one-title-id"
                    style={{
                        fontSize:  this.cardSize/7,
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignContent: 'center',
                        height: this.cardSize, 
                        width:  this.cardSize, 
                        borderRadius: '50%', 
                        verticalAlign: 'center',
                        //transform: 'translate('+(this.props.size/-2).toString()+'px, '+(this.props.size/-2).toString()+'px)'
                    }} 
                >
                    {this.state.title}
                </h1>

*/