import React, {Component} from 'react';
import {Planet} from 'react-planet';

import Center from './Center.js'
import Card from './Card.js';
import Greeting from './Greeting.js'
import Anmeldung from './Anmeldung.js'
import Rundgang from './Rundgang.js'

import greeting from '../media/Begrüßung.png'
import anmeldung from '../media/anmeldung.png'
import begys from '../media/begys.png'
import bili from '../media/Bili.png'
import biologie from '../media/bio.jpg'
import dg from '../media/DarstellendesSpiel.png'
import deutsch from '../media/Deutsch.png'
import entdecken from '../media/Entdecken.png'
import englisch from '../media/Englisch.png'
import erdkunde from '../media/Erdkunde.jpg'
import fachbereiche from '../media/Fachbereiche.png'
import franzoesisch from '../media/Französisch.png'
import geschichte from '../media/Geschichte.jpg'
import it from '../media/IT.png'
import bildendeKunst from '../media/Kunst.png'
import kunste from '../media/kunste.png'
import latein from '../media/Latein.png'
import schwerdLuft from '../media/luftbild.jpg'
import mathematik from '../media/Mathematik.png'
import mint from '../media/MINT.png'
import musik from '../media/Musik.png'
import nawi from '../media/NawiBioChemie.png'
import ruanda from '../media/Partnerschule Ruanda.png'
import schuledessport from '../media/PartnerschuleDesSports.png'
import physik       from '../media/Physik.png'
import projektklassen from '../media/Projektklassen.jpg'
import religion     from '../media/Religion_Ethik.png'
import rundgang     from '../media/RundgangAbi2020.png'
import soklasse     from '../media/So-Klasse.png'
import sozialkunde  from '../media/Sozialkunde.png'
import spanisch     from '../media/Spanisch.png'
import sprachen from '../media/Sprachen.png'


const mapper={
    "anmeldung":anmeldung,
    "greeting":greeting,
    "begys" : begys,
    "bili": bili,
    "biologie": biologie,
    "dg": dg,
    "deutsch":deutsch,
    "entdecken":entdecken,
    "englisch":englisch,
    "erdkunde":erdkunde,
    "fachbereiche":fachbereiche,
    "franzoesisch":franzoesisch,
    "geschichte":geschichte,
    "it":it,
    "bildendeKunst":bildendeKunst,
    "kunste": kunste,
    "latein":latein,
    "schwerdLuft":schwerdLuft,
    "mathematik" :mathematik,
    "mint":mint,
    "musik":musik,
    "nawibiochemie" :nawi,
    "ruanda":ruanda,
    "schuledessport":schuledessport,
    "physik"       :physik,
    "projektklassen": projektklassen,
    "religion"   :religion,
    "rundgang"    : rundgang,
    "soklasse"    :soklasse,
    "sozialkunde"  :sozialkunde,
    "spanisch":spanisch,
    "sprachen": sprachen
} 

const data = require('../data/subjectData.json')
const firstLayer = require('../data/mainData.json')
const projectClassData = require('../data/projectclassData.json')
const mintData = require('../data/mintData.json')

var sizes = {
    "layerOne":150,
    "radiusOne": 250,
    "layerTwo":100,
    "radiusTwo":100,
    "layerThree":60,
    "radiusThree":80
}

const allCards = []


class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            sizeX: window.InnerWidth,
            sizeY: window.innerHeight,
            currentSize: props.currentSize,
            currentOpened: "",
            firstLayerObjects: 0,
            cardsLength: 0,
            horizontalShift: 0
        };
        this.prepareCards();
        this.openCard = this.openCard.bind(this)
        this.handleResize = this.handleResize.bind(this)
        

        window.addEventListener('resize', this.handleResize)
        
    }

    componentDidMount(){
        this.hideAllCards();
        this.handleResize();
        this.setState({horizontalShift: sizes.layerOne/2})
        window.addEventListener('click', this.handleClick)
        this.handleClick = this.handleClick.bind(this)      
    }

    hideAllCards(){
        const temp = document.getElementsByClassName("card-wrapper")
        for(var i = 0; i<temp.length; i++){
            temp.item(i).style.display = "none"
        }
    }

    handleResize(e){
        sizes.layerOne = window.innerWidth/8;
        sizes.radiusOne = window.innerWidth/5.5;

        sizes.layerTwo = window.innerWidth/12.65;
        sizes.radiusTwo = window.innerWidth/12.65;

        sizes.layerThree = window.innerWidth/21.08;
        sizes.radiusThree = window.innerWidth/15.75;

        this.setState({
            sizeX: window.innerWidth, 
            sizeY: window.innerHeight,
        })       
    }

    setHorizontalShift(value){
        this.setState({horizontalShift: value})
    }

    handleClick = (e) => {
        if(e.toElement.id === "trigger-button-fachbereiche"){
        /*
            if(document.getElementById(e.toElement.id).className ==="trigger-button-closed"){

                document.getElementById(e.toElement.id).className = "trigger-button-opened";
                this.setHorizontalShift(window.innerWidth/4)

            } else if(document.getElementById(e.toElement.id).className ==="trigger-button-opened"){

                document.getElementById(e.toElement.id).className = "trigger-button-closed";
                this.setHorizontalShift(sizes.layerOne/2)

            } 
        */
        }
        
    }

    makeImages(e){
        var i
        if (e.name !== undefined){
            i = (
                <img
                    className= {"image-cover"}
                    src={mapper[e.link]} 
                    alt=""
                    style={{
                        overflow: 'hidden',
                        position: "absolute",
                        objectFit: "fill", 
                        borderRadius: '50%',
                        height: '100%'
                    }}
                >
                </img>
            )
        }
        else{
            i = (
                <img
                    className= {`image-cover-${e}`}
                    src={mapper[e]} 
                    alt=""
                    style={{
                        overflow: 'hidden',
                        position: "absolute",
                        objectFit: "fill", 
                        borderRadius: '50%',
                        height: '100%'
                    }}
                >
                </img>
            )
        }
        return i
    }

    prepareCards(){
        allCards.push(
            <Greeting
                key={"greeting-virtual-key"}
                id= {"greeting"}
                data={firstLayer[0]}
                color={firstLayer[0].color} 
            />
        )
        allCards.push(
            <Anmeldung
                key={"anmeldung-virtual-key"}
                id= {"anmeldung"}
            />
        )
        allCards.push(
            <Rundgang key={"rungang-card-key"}/>
        )
        
        mintData.map((dat) => 
            allCards.push(
                <Card
                    key={`card-wrapper${dat.link}`}
                    data={{...dat}}>
                </Card>
            ) 
        )
        data.map((dat) => 
            dat.content.map((d) => 
                allCards.push(
                    <Card
                        key={`card-wrapper${d.link}`}
                        data={{...d}}>
                    </Card>
                )
            )
        )
        allCards.push(
            <Card 
                key="ruanda-virtual-key"
                id="ruanda"
                data={firstLayer[6]}
                color={firstLayer[6].color}
            />
        )
        allCards.push(
            <Card 
                key="schuledessport-virtual-key"
                id="schuledessport"
                data={firstLayer[5]}
                color={firstLayer[5].color}
            />
        )
        allCards.push(
            <Card
                key="soklasse-virtual-key"
                id="soklasse"
                data={firstLayer[4]}
                color={firstLayer[4].color}
            />
        )  
        projectClassData.map((e) =>
            allCards.push(
                <Card 
                    key={`card-wrapper${e.link}`}
                    id={e.link}
                    data={{...e}}
                />
            )
        )
        
    }

    openCard(e){
        this.hideAllCards()
        document.getElementById("card-wrapper-"+e.target.value).style.display="block"
        this.setState({currentOpened: e.target.value})
        if(e.target.value==="it"){
            console.log("refreshing for iframe")
            document.getElementById('iframe-player-id').src = document.getElementById('iframe-player-id').src
        }
    }

    makeGreetings(){
        const greetings=[]
        greetings.push(
            <div
                key={"secondLayerObject-greeting"}
                className="icon-wrapper"
                style={{
                    background: '#f98013aa', 
                    width: sizes.layerTwo,
                    height: sizes.layerTwo,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {this.makeImages("greeting")}
                <button
                    style={{
                        width: sizes.layerTwo,
                        height: sizes.layerTwo,                
                        background: "none",
                        fontSize: '1.1em',
                        fontWeight: 400,
                        textAlign: 'center',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        zIndex: 1
                    }}
                    onClick={this.openCard}
                    value={"greeting"}
                >
                    Begrüßung
                </button>
            </div>
        )
        greetings.push(
            <div
                key={"secondLayerObject-rundgang"}
                className="icon-wrapper"
                style={{
                    background: '#f98013aa', 
                    width: sizes.layerTwo,
                    height: sizes.layerTwo,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {this.makeImages("rundgang")}
                <button
                    style={{
                        width: sizes.layerTwo,
                        height: sizes.layerTwo,                
                        background: "none",
                        fontSize: '1.1em',
                        fontWeight: 400,
                        textAlign: 'center',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        zIndex: 1
                    }}
                    onClick={this.openCard}
                    value={"rundgang"}
                >
                    Rundgang
                </button>
            </div>
        )
        greetings.push(
            <div key={"blankkey-1"} />
        )
        greetings.push(
            <div key={"blankkey-2"}/>
        )
        return greetings
    }

    makeMintclasses(){
        const mintclasses = []
        mintclasses.push(
            mintData.map((e)=>
                <div
                    className="icon-wrapper"
                    style={{
                        background: '#f98013aa', //firstLayer[i].color,
                        width: sizes.layerTwo,
                        height: sizes.layerTwo,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {this.makeImages(e)}
                    <button
                        style={{
                            width: sizes.layerTwo,
                            height: sizes.layerTwo,                
                            background: "none",
                            fontSize: '1.1em',
                            fontWeight: 400,
                            textAlign: 'center',
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            zIndex: 1
                        }}
                        onClick={this.openCard}
                        value={e.link}
                    >
                        {e.name}
                    </button>
                </div>
            )
        )
        mintclasses.push(
            <div key={"blankkey-7"}/>
        )
        mintclasses.push(
            <div key={"blankkey-8"}/>
        )
        mintclasses.push(
            <div key={"blankkey-9"}/>
        )
        return mintclasses
    }

    makeProjectclasses(){
        const projectclasses = []
        projectclasses.push(
            projectClassData.map((e)=>
                <div
                    className="icon-wrapper"
                    style={{
                        background: '#f98013aa', //firstLayer[i].color,
                        width: sizes.layerTwo,
                        height: sizes.layerTwo,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {this.makeImages(e)}
                    <button
                        style={{
                            background: 'none',
                            border: 'none',
                            fontSize: sizes.layerTwo/6,
                            textAlign: 'center',
                            fontWeight: 400,
                            cursor: 'pointer',
                            alignSelf: 'center',
                            zIndex: 1,
                            width: '120%',
                            height: '100%'
                        }}
                        onClick={this.openCard}
                        value={e.link}
                    >
                        {e.name}
                    </button>
                </div>
            )
        )
        projectclasses.push(
            <div key={"blankkey-3"}/>
        )
        projectclasses.push(
            <div key={"blankkey-4"}/>
        )
        return projectclasses
    }

    makeSubjects(index){
        const subjects = []
        subjects.push(
            data[index].content.map((e) =>
                <div
                    className="icon-wrapper"
                    style={{
                        background: '#f98013aa', //firstLayer[i].color,
                        width: sizes.layerThree,
                        height: sizes.layerThree,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {this.makeImages(e)}
                    <button
                        style={{
                            width: sizes.layerThree,
                            height: sizes.layerThree,                
                            background: "none",
                            fontSize: '1.1em',
                            fontWeight: 400,
                            textAlign: 'center',
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            zIndex: 1
                        }}
                        onClick={this.openCard}
                        value={e.link}
                    >
                        {e.name}
                    </button>
                </div>
            )
        )
        return subjects
    }

    makeFacilities(){
        const facilities = []
        for(var i = 0; i<4; i++){
            data[i].content.map((e) =>
            facilities.push(
                <div
                    key={`icon-wrapper-${e.link}`}
                    className="icon-wrapper"
                    style={{
                        transform: `translateX(${-sizes.layerTwo/4}px)`,
                        background: '#f98013aa', //firstLayer[i].color,
                        width: sizes.layerThree,
                        height: sizes.layerThree,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {this.makeImages(e)}
                    <button
                        style={{
                            fontSize: sizes.layerTwo/6,
                            fontWeight: 400,
                            textAlign: 'center',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            zIndex: 1,
                            width: '150%'
                        }}
                        onClick={this.openCard}
                        value={e.link}
                    >
                        {e.name}
                    </button>
                </div>                
                )
                )}
                /*
                <div
                    key={("facility-"+i)}
                    style={{
                        transform: 'translate(-' + (sizes.layerTwo/2) + 'px, -' + (sizes.layerTwo/2) + 'px)',
                        zIndex : 1
                    }}
                >
                    <Planet
                        autoClose
                        orbitRadius = {sizes.radiusThree}         
                        rotation={45}
                        centerContent={
                            <div
                                className="icon-wrapper"
                                style={{
                                    background: '#f98013aa', //firstLayer[i].color,
                                    width: sizes.layerTwo,
                                    height: sizes.layerTwo,
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {this.makeImages(data[i])}
                                <h1
                                    style={{
                                        fontSize: sizes.layerTwo/6,
                                        textAlign: 'center',
                                        fontWeight: 400,
                                        cursor: 'pointer',
                                        alignSelf: 'center'
                                    }}
                                >
                                    {data[i].name}
                                </h1>
                            </div>
                        }
                    >
                        {this.makeSubjects(i)}
                    </Planet>
                </div>
            )}
            for(var j = 2; j<4; j++){
            facilities.push(
                <div
                    key={`icon-wrapper-${j}`}
                    className="icon-wrapper"
                    style={{
                        background: '#f98013aa', //firstLayer[i].color,
                        width: sizes.layerTwo,
                        height: sizes.layerTwo,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {this.makeImages(data[j].content[0])}
                    <button
                        style={{
                            fontSize: sizes.layerTwo/6,
                            fontWeight: 400,
                            textAlign: 'center',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            zIndex: 1,
                            width: '150%'
                        }}
                        onClick={this.openCard}
                        value={data[j].content[0].link}
                    >
                        {data[j].content[0].name}
                    </button>
                </div>
                */
        facilities.push(
            <div key={"blankkey-5"} />
        )
        facilities.push(
            <div key={"blankkey-6"} />
        )
        return facilities
    }

    makeFirstLayer(){
        const layer = []
        for(var i = 0; i<firstLayer.length; i++){
            if(firstLayer[i].submenus === 0){
                layer.push(
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        className="icon-wrapper"
                        key={("firstLayerObject-"+i)}
                        style={{
                            background: '#f98013aa', //firstLayer[i].color,
                            width: sizes.layerTwo,
                            height: sizes.layerTwo,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >       
                        {this.makeImages(firstLayer[i])}                     
                        <button
                            id={"trigger-button-"+firstLayer[i].link}
                            className="trigger-button-closed"
                            style={{
                                width: sizes.layerTwo,
                                height: sizes.layerTwo,                
                                background: "none",
                                fontSize: sizes.layerTwo/5,
                                fontWeight: 700,
                                textAlign: 'center',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                zIndex:1
                            }}
                            onClick={this.openCard}
                            value={firstLayer[i].link}
                        >
                            {firstLayer[i].hovertext}
                        </button>
                    </div>
                    
                )
            }
            //https://www.fmsg-speyer.de/orientierungsstufe/articles/anmeldung-432
            if(firstLayer[i].name === "Anmeldezeiten"){
                layer.push(
                    
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        className="icon-wrapper"
                        key={("firstLayerObject-"+i)}
                        style={{
                            background: '#f98013aa', //firstLayer[i].color,
                            width: sizes.layerTwo,
                            height: sizes.layerTwo,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    > 
                    {this.makeImages(firstLayer[i])}   
                        <a 
                            href ="https://www.fmsg-speyer.de/orientierungsstufe/articles/anmeldung-432"
                            className="icon-wrapper"
                            style={{
                                zIndex:1,
                                color: "black",
                                textDecoration: "none",   
                            }}
                        >      
                            <h1
                                id={"trigger-button-"+firstLayer[i].link}
                                className="trigger-button-closed"
                                style={{    
                                    color: "black",
                                    textDecoration: "none",                                                   
                                    background: "none",
                                    fontSize: sizes.layerTwo/5,
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    border: 'none',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    zIndex:1
                                }}
                                >
                                    {firstLayer[i].hovertext}
                            </h1>           
                        </a>
                    </div>                    
                )
            }
            if(firstLayer[i].submenus === 1 && firstLayer[i].name === "Schule entdecken"){
                layer.push(
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        key={("firstLayerObject-"+i)}
                        style={{
                            transform: 'translate(-'+sizes.layerTwo/2+'px, -'+sizes.layerTwo/2+'px)',
                            dispay: 'flex',
                        }}
                    >
                        <Planet
                            hideOrbit
                            autoClose
                            orbitRadius={sizes.radiusTwo}
                            rotation={90}
                            centerContent={
                                <div
                                    className="icon-wrapper"
                                    key={("firstLayerObject-"+i)}
                                    style={{
                                        background: '#f98013aa', //firstLayer[i].color,
                                        width: sizes.layerTwo,
                                        height: sizes.layerTwo,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                > 
                                    {this.makeImages(firstLayer[i])}
                                    <h1
                                        id={"trigger-button-"+firstLayer[i].link}
                                        className="trigger-button-closed"
                                        style={{                                                       
                                            background: "none",
                                            fontSize: sizes.layerTwo/5,
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            zIndex:1
                                        }}
                                    >
                                        {firstLayer[i].hovertext}
                                    </h1>                                   
                                </div>
                                
                            }
                        >
                            {this.makeGreetings()}
                        </Planet>
                    </div>
                )
            }
            
            if(firstLayer[i].submenus === 1 && firstLayer[i].name === "Projektklassen"){
                layer.push(
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        key={("firstLayerObject-"+i)}
                        style={{
                            transform: 'translate(-'+sizes.layerTwo/2+'px, -'+sizes.layerTwo/2+'px)',
                            dispay: 'flex',
                        }}
                    >
                        <Planet
                            hideOrbit
                            autoClose
                            rotation={102.8}
                            orbitRadius={sizes.radiusTwo}
                            centerContent={
                                <div
                                    className="icon-wrapper"
                                    key={("firstLayerObject-"+i)}
                                    style={{
                                        background: '#f98013aa', //firstLayer[i].color,
                                        width: sizes.layerTwo,
                                        height: sizes.layerTwo,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                > 
                                    {this.makeImages(firstLayer[i])}
                                    <h1
                                        id={"trigger-button-"+firstLayer[i].link}
                                        className="trigger-button-closed"
                                        style={{                                                       
                                            background: "none",
                                            fontSize: sizes.layerTwo/5,
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            zIndex:1
                                        }}
                                    >
                                        {firstLayer[i].hovertext}
                                    </h1>                                   
                                </div>
                                
                            }
                        >
                            {this.makeProjectclasses()}
                        </Planet>
                    </div>
                )
            }
            if(firstLayer[i].submenus === 1 && firstLayer[i].name === "Mint-freundliche Schule"){
                layer.push(
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        key={("firstLayerObject-"+i)}
                        style={{
                            transform: 'translate(-'+sizes.layerTwo/2+'px, -'+sizes.layerTwo/2+'px)',
                        }}
                    >
                        <Planet
                            hideOrbit
                            autoClose
                            rotation={225}
                            orbitRadius={sizes.radiusTwo}
                            centerContent={
                                <div
                                    className="icon-wrapper"
                                    style={{
                                        background: '#f98013aa', //firstLayer[i].color,
                                        width: sizes.layerTwo,
                                        height: sizes.layerTwo,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {this.makeImages(firstLayer[i])}
                                    <h1
                                        id={"trigger-button-"+firstLayer[i].link}
                                        className="trigger-button-closed"
                                        style={{          
                                            background: "none",
                                            fontSize: sizes.layerTwo/5,
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            zIndex:1
                                        }}
                                        
                                    >
                                        {firstLayer[i].hovertext}
                                    </h1>
                                </div>
                                
                            }
                        >
                            {this.makeMintclasses()}
                        </Planet>
                    </div>
                )
            }
            if(firstLayer[i].submenus === 2){
                layer.push(
                    <div
                        id={`firstlayerObject-${firstLayer[i].link}`}
                        key={("firstLayerObject-"+i)}
                        style={{
                            transform: 'translate(100px, -'+sizes.layerTwo/2+'px)',
                        }}
                    >
                        <Planet
                            hideOrbit
                            orbitRadius={sizes.radiusTwo*1.3}
                            autoClose
                            rotation={120}
                            centerContent={
                                <div
                                    className="icon-wrapper"
                                    style={{
                                        transform: `translate(${-100-sizes.layerTwo/2}px,0px)`,
                                        background: '#f98013aa', //firstLayer[i].color,
                                        width: sizes.layerTwo,
                                        height: sizes.layerTwo,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {this.makeImages(firstLayer[i])}
                                    <h1
                                        id={"trigger-button-"+firstLayer[i].link}
                                        className="trigger-button-closed"
                                        style={{             
                                            background: "none",
                                            fontSize: sizes.layerTwo/5,
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            verticalAlign: 'center',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            zIndex:1
                                        }}
                                    >
                                        {firstLayer[i].hovertext}
                                    </h1>
                                </div>
                            }
                        >
                            {this.makeFacilities()}
                        </Planet>
                    </div>
                )
            }
        }
        return layer
    }

    render(){
        return (
            <>
                <div 
                    className="all-planets-wrapper"   
                    style={{
                        justifySelf: 'center',
                        transform: `translate(-${this.state.horizontalShift}px, 40vh)`
                    }}
                >   
                    <Planet
                        centerContent={
                            <Center
                                size = {sizes.layerOne}>
                            </Center>
                        }
                        
                        bounceOnOpen
                        orbitRadius= {sizes.radiusOne}
                        rotation= {180-(360/(firstLayer.length*2))}
                    >
                        {this.makeFirstLayer()}
                        
                    </Planet>                               
                </div>
                <div
                    style={{
                        justifySelf: 'center'
                    }}
                >
                    {allCards}
                    
                </div>
            </> 
        )
    }    
}


export default Home;

/*




datacopy.map((temp) => {
    console.log(temp.category)
    temp.content.map((innertemp) =>
        console.log(innertemp)
    )
}


<ul className = "card-orbit-wrapper">
                {data.map((dat)=>
                    <Card 
                        className="card-orbit"
                        title={dat.fachschaftsName} 
                        description={dat.fachschaftsBeschreibung}>
                    </Card>
                )}
            </ul>




             <Card style={{
                    height: 200, 
                    width: 200,
                    borderRadius: '50%',
                    background: '#b4e1c8'}}

                    className="card-orbit-category"
                    fachschaftsName={d.fachschaftsName} 
                    fachschaftsBeschreibung={dat.fachschaftsBeschreibung}>
                </Card>
            

                
                <Planet
                                            centerContent={
                                                <div 
                                                    style={{
                                                        background: d.color,
                                                        width: sizes.layerThree,
                                                        height: sizes.layerThree,
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <h1
                                                        style={{
                                                            fontSize: '1.1em',
                                                            textAlign: 'center'
                                                        }}
                                                    >{d.name}</h1>
                                                </div>
                                            }
                                            bounceOnOpen
                                            autoClose
                                            orbitRadius= {160}
                                            onClose={this.handleClose}
                                        >
                                            <div
                                                className="outer-card-wrapper"
                                                style={{
                                                    display: "unset"
                                                }}
                                            >
                                                <Videoplayer 
                                                    data={{...d}}
                                                >
                                                    
                                                </Videoplayer>
                                            </div>
                                            
                                        </Planet>
            
            











                                        {data.map((dat) => 
                            <div
                            className="layerTwo-planet-wrapper"
                            key={dat.id}
                            style={{
                                transform: "translate(-" + sizes.layerTwo/2 + "px, -" + sizes.layerTwo/2 + "px)"
                            }}
                            >
                                <Planet 
                                    centerContent={
                                        <div 
                                            style={{
                                                background: dat.color,
                                                width: sizes.layerTwo,
                                                height: sizes.layerTwo,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontSize: sizes.layerTwo/5,
                                                    textAlign: 'center'
                                                }}
                                            >{dat.name}</h1>
                                        </div>
                                    }
                                    bounceOnOpen
                                    autoClose
                                    orbitRadius= {sizes.layerTwo*1.35}
                                >
                                    <Planet
                                        centerContent={
                                            <div
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    borderRadius: '50%',
                                                    backgroundColor: '#1da8a4',
                                                }}
                                            />
                                        }
                                    >
                                        {dat.content.map((d) => 
                                            <div
                                            className="layerThree-planet-wrapper"
                                            key={d.id} 
                                            >
                                                <div 
                                                style={{
                                                    background: d.color,
                                                    width: sizes.layerThree,
                                                    height: sizes.layerThree,
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                >
                                                    <button
                                                        style={{
                                                            fontSize: sizes.layerThree/4.5,
                                                            textAlign: 'center',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={this.openCard}
                                                        value={d.fachschaftsLink}
                                                    >
                                                        {d.name}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Planet>
                                </Planet>
                            </div>
                        )}


                */