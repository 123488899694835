import React, {Component} from 'react';

class Card extends Component{ 

    constructor(props) {
        super(props);
        this.closeCard = this.closeCard.bind(this);
    }



    closeCard(e){
        document.getElementById("videoplayer-rundgang").pause()
        document.getElementById("card-wrapper-rundgang").style.display = "none"
    }

    

    render(){
        return(
            <div
                className="card-wrapper"
                id={"card-wrapper-rundgang"}
                style={{
                    position: 'fixed',
                    background: 'whitesmoke',
                    gridTemplateRows: '2',
                    gridTemplateColumns: '2',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    justifySelf: 'center',
                    margin: '1%',
                    padding: '1%',
                    borderRadius: 20,
                    width : '1100px',
                    maxHeight: '80vh',
                    zIndex: 101,
                    boxShadow: '0px 10px 20px #848484',
                    overflowY: 'auto'  
                }}
            >
                <button
                    style={{
                        float: 'right',
                        background: "#FF3232",
                        color: 'white',
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        position: 'sticky',
                        top: 0,
                        right: '0%',
                        border: "none",
                        borderRadius: '50%',
                        fontSize: '2em',
                        zIndex: 1
                    }}
                    value={"rundgang"}
                    onClick = {this.closeCard}
                >
                    &#10006;
                </button>
                <div 
                    className="video-wrapper"
                    style={{
                        margin: '1em 0 0 0', 
                        display: "flex",
                        flexWrap: 'wrap',
                        
                    }}    
                >
                
                <video 
                    id={"videoplayer-rundgang"}
                    controls
                >
                    <source src={"https://fmsg-speyer.de/files/fmsg/img/filme/Rundgang%20Abi%202020.mp4"} type="video/mp4" />
                </video>
                        
                </div>
                
                <div className="separator"
                    style={{
                        width: 40,
                        height: 10,
                        background: this.props.color,
                        margin: '0 0 1em 0'
                    }}
                ></div>
                <h3
                    style={{
                        fontSize: '3em',
                        margin: '0 0 .2em 0'
                    }}    
                >
                    Ein Rundgang durch die Schule
                </h3>
                <div className="separator"
                    style={{
                        width: 80,
                        height: 20,
                        background: '#f98013',
                        margin: '0 0 2em 0'
                    }}
                ></div>
            </div>             
        )    
    }
}   
export default Card;

/*

<div style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: '0'
                    }}   
                    >
                        <h2
                            style={{
                                margin: '0 0 .3em 0',
                            }}
                        >{this.props.data.media.title}</h2>
                        <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: this.props.data.color,
                                margin: '0 0 1em 0'
                            }}
                        ></div>
                        <p
                            style={{
                                margin: '0 0 2em 0',
                            }}
                        >{this.props.data.media.description}</p>
                    </div>

*/