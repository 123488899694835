import React, {Component} from 'react';

class Greeting extends Component{ 
    
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            data: props.data,
            color: "#111111"
        }; 
        this.closeCard = this.closeCard.bind(this);
    }

    closeCard(e){
        document.getElementById("videoplayer-greeting").pause()
        document.getElementById("card-wrapper-greeting").style.display = "none"
    }

    render(){
        return(
            <div
                className="card-wrapper"
                id={"card-wrapper-greeting"}
                style={{
                    position: 'fixed',
                    background: 'whitesmoke',
                    gridTemplateRows: '2',
                    gridTemplateColumns: '2',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    justifySelf: 'center',
                    margin: '1%',
                    padding: '1%',
                    borderRadius: 20,
                    width : '1100px',
                    maxHeight: '80vh',
                    zIndex: 101,
                    boxShadow: '0px 10px 20px #848484',
                    overflowY: 'auto'  
                }}
            >
                <button
                    style={{
                        float: 'right',
                        background: "#FF3232",
                        color: 'white',
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        position: 'sticky',
                        top: 0,
                        right: '0%',
                        border: "none",
                        borderRadius: '50%',
                        fontSize: '2em',
                        zIndex: 1
                    }}
                    value={this.props.id}
                    onClick = {this.closeCard}
                >
                    &#10006;
                </button>
                    <video
                        id="videoplayer-greeting"
                        controls>
                        <source src={this.props.data.media.uri} type="video/mp4" />
                    </video>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: '0'
                    }}   
                    >
                        <h3
                            style={{
                                margin: '0 0 .3em 0',
                            }}
                        >Willkommen zum digitalen Infotag</h3>
                        <div className="separator"
                            style={{
                                width: 80,
                                height: 20,
                                background: this.props.color,
                                margin: '0 0 2em 0'
                            }}
                        ></div>
                        <p
                            style={{
                                margin: 0,
                            }}
                        >{this.props.data.media.description}</p>
                    </div>
                </div>
                               
        )    
    }
}   
export default Greeting;

/*

*/