import React, { Component } from 'react';

class Faq extends Component{
    render(){
        return (
            <dl className="legals-list"
                id="faq-list"
                style={{
                    display: this.props.data.faqActive?'block':'none'
                }}
            >
                
                    <dt>Wie bediene ich die App?</dt>
                    <dd>
                        <p>Die Kreise stellen verschiedene Ebenen dar. Durch einen Klick auf eine Ebene gelangt man in diese hinein. Nun werden die Elemente, die in der gerade angeklickten Ebene enthalten sind angezeigt. Die unterste Ebene bilden die Fachschaften.</p>
                        <p>Falls es Probleme gibt ist es ratsam, das Browserfenster einfach neu zu laden (F5 Taste). Dadurch wird die App neu gestartet.</p>
                    </dd>
                
                    <dt>Speichert die App meine Daten?</dt>
                    <dd>Die App speichert keinerlei Nutzerdaten. Es werden keine Cookies gespeichert.</dd>
                
                    <dt>Muss ich JavaScript aktiviert haben?</dt>
                    <dd>Die App funktioniert nur mit eingeschaltetem JavaScript.</dd>
                
                    <dt>Welchen Browser sollte ich benutzten, um die App zu bedienen?</dt>
                    <dd>Am besten funktioniert die App in Google Chrome oder Firefox.</dd>
                
                    <dt>Wie schließe ich die FAQs?</dt>
                    <dd>Einfach erneut auf den Button "FAQs" unten klicken.</dd>
                
                    <dt>Auf welchen Endgeräten kann man die App benutzen?</dt>
                    <dd>Derzeit ist die App nur für Tablet, Laptop und Desktop-PC optimiert. Mit Smartphones kann man die App nicht bedienen.</dd>
                
            </dl>
        )
    }
    
}

export default Faq;