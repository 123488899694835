//Modules
import React from 'react';
import ReactDOM from 'react-dom';

import App from '../src/scripts/App.js';
import Header from '../src/scripts/Header.js'
import Footer from '../src/scripts/Footer.js'

//styles
import '../src/styles/style.css';


ReactDOM.render(
  <div className="page-wrapper">
    <Header />
    <App />
    <Footer />
    
  </div>, document.getElementById('root')
);

 