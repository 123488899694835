import { whitesmoke } from 'color-name';
import React, {Component} from 'react';

class Anmeldung extends Component{ 
    
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            data: props.data,
            color: "#111111"
        }; 
        this.closeCard = this.closeCard.bind(this);
    }

    closeCard(e){
        document.getElementById("card-wrapper-anmeldung").style.display = "none"
    }

    render(){
        return(
            <div
                className="card-wrapper"
                id={"card-wrapper-anmeldung"}
                style={{
                    position: 'fixed',
                    background: 'whitesmoke',
                    gridTemplateRows: '2',
                    gridTemplateColumns: '2',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    justifySelf: 'center',
                    margin: '1%',
                    padding: '1%',
                    borderRadius: 20,
                    width : '1100px',
                    maxHeight: '80vh',
                    zIndex: 101,
                    boxShadow: '0px 10px 20px #848484',
                    overflowY: 'auto' 
                }}
            >
                <button
                    style={{
                        float: 'right',
                        background: "#FF3232",
                        color: 'white',
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        position: 'sticky',
                        top: 0,
                        right: '0%',
                        border: "none",
                        borderRadius: '50%',
                        fontSize: '2em',
                        zIndex: 1
                    }}
                    value={this.props.id}
                    onClick = {this.closeCard}
                >
                    &#10006;
                </button>
                <dl className="info-list">
                    <dt className="info-title">Anmeldetermine Schuljahr 2021/22</dt>
                    <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: '#f98013',
                                margin: '0 0 .5em 0'
                            }}
                        ></div>
                    <dd className="info-text">- Samstag, 20. Februar 2021 von 8.00 bis 12.00 Uhr</dd>
	                <dd className="info-text">- Montag, 22. Februar 2021 von 8.00 bis 12.00 Uhr und 14.00 bis 16.00 Uhr</dd>
	                <dd className="info-text">- Dienstag, 23. Februar 2021 von 8.00 bis 12.00 Uhr und 14.00 bis 16.00 Uhr</dd>

                    <dt className="info-title">Bitte zur Anmeldung mitbringen:</dt>
                    <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: '#f98013',
                                margin: '0 0 .5em 0'
                            }}
                        ></div>
                    <dd className="info-text">- Kopie des Halbjahreszeugnisses</dd>
	                <dd className="info-text">- Geburtsurkunde oder Familienstammbuch</dd>
	                <dd className="info-text">- Anmeldebogen der Grundschule</dd>

                    <div className="info-advice-wrapper">
                        <span className="info-advice-marker">Wichtiger Hinweis: </span> 
                        <p className="info-advice">
                            Wir dürfen wegen behördlicher Vorgaben (wie alle Staatlichen Gymnasien in Speyer) Anmeldungen erst 
                            zu den genannten Terminen annehmen, zumal für die Anmeldung die Vorlage des Halbjahreszeugnisses notwendig ist. 
                        </p>
                        <p className="info-advice">
                            Eine vorherige Bewerbung um einen Schulplatz ist an den Staatlichen Gymnasien nicht erforderlich.
                        </p>
                    </div>
                    

                    <dt className="info-title">Nachmittagsbetreuung</dt>
                    <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: '#f98013',
                                margin: '0 0 .5em 0'
                            }}
                        ></div>
                    <dd className="info-text">Hinweise zur Nachmittagsbetreuung finden Sie auf der <a className="info-link" href="https://www.fmsg-speyer.de/downloads">Download-Seite</a> unserer Homepage.</dd>

                    <dt className="info-title">Beratungstermine</dt>
                    <div className="separator"
                            style={{
                                width: 40,
                                height: 10,
                                background: '#f98013',
                                margin: '0 0 .5em 0'
                            }}
                        ></div>
                    <dd className="info-text">Beratungstermine terminieren Sie bitte telefoniosch unter <span className="info-phone">06232/141600</span></dd>
                    <dd className="info-text">oder per Mail an <a className="info-link" href="mailto:info@fmsg-speyer.de">info@fmsg-speyer.de</a></dd>

                </dl>
            </div>            
        )    
    }
}   
export default Anmeldung;

/*

*/